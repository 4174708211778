.op-sidebar
  height: 100%
  display: flex
  flex-direction: column
  overflow: hidden

  &--search
    margin: 12px
    color: var(--main-menu-font-color)
    display: flex
    flex-direction: column
    row-gap: 16px

  &--search-input
    // Overriding the defult input styles, because of the default dark background of the sidebar
    color: var(--main-menu-font-color) !important
    border-color: var(--borderColor-muted) !important

  &--body
    flex-grow: 1
    overflow: auto
    @include styled-scroll-bar

    &:only-child
      padding-bottom: 10px

  &--footer
    display: grid
    text-align: center
    padding: 1rem

    @supports (-webkit-touch-callout: none)
      padding: 1rem 1rem 5rem

    .button .spot-icon
      margin-right: 0.5rem
