.op-sidemenu
  &--title
    display: flex
    justify-content: space-between
    background: transparent
    color: var(--main-menu-fieldset-header-color)
    border: 1px solid transparent
    text-transform: uppercase
    padding: 8px 12px 8px 24px
    margin-top: 12px
    font-size: 12px
    cursor: pointer
    width: 100%


    &:hover
      background: var(--main-menu-bg-hover-background)
      color: var(--main-menu-hover-font-color)
      border-color: var(--main-menu-hover-border-color)

  &--items
    list-style: none

    &_collapsed
      display: none

  &--item-action
    display: flex
    align-items: center
    color: var(--main-menu-font-color)
    padding: 8px 12px 8px 32px

    &:hover
      background: var(--main-menu-bg-hover-background)
      color: var(--main-menu-hover-font-color)

    &_active
      background: var(--main-menu-bg-selected-background)
      color: var(--main-menu-selected-font-color)

    &_active-child
      background: var(--main-menu-bg-selected-background)
      color: var(--main-menu-selected-font-color)

    &_with_icon
      padding-left: 12px

  &--item-title
    display: inline-block
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap
    line-height: 30px
    text-decoration: none

  &--item-icon
    font-size: 24px
    margin-right: 8px

  &--item-mark
    margin-left: 8px
